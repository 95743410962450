exports.videos = [
	{
		path: "lostboys.mp4",
		title: "2/6",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},

	{
		path: "lucky-wpns.mp4",
		title: "3/4",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},

	{
		path: "raid2.mp4",
		title: "unit ?",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},
	{
		path: "og.mp4",
		title: "unit ?  ",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},
	{
		path: "prince.mp4",
		title: "2/8",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},
	{
		path: "3rdTracks.mp4",
		title: "3rD AABN",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},

	{
		path: "withdaboys.mp4",
		title: "Unit ?",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},

	{
		path: "retardcousins.mp4",
		title: "Unit ?",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},
	{
		path: "raid1.mp4",
		title: "unit ?",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},
	{
		path: "282late.mp4",
		title: "2/8",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},
	{
		path: "3-1Lima.mp4",
		title: "3/1",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},
	{
		path: "2-4Fox.mp4",
		title: "2/4",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},
	{
		path: "thinkwesuck.mp4",
		title: "2/8",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},
	{
		path: "army-inf.mp4",
		title: "Unit ?",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},
	{
		path: "bigLpatrol.mp4",
		title: "Unit ?",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},
	{
		path: "moto.mp4",
		title: "Unit ?",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},

	{
		path: "3-2CAAT.mp4",
		title: "3/2",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},

	{
		path: "3-187.mp4",
		title: "3/2",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},

	{
		path: "kbay.mp4",
		title: "Unit ?",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},
	{
		path: "raid3.mp4",
		title: "unit ?",
		poster: "https://www.levistrauss.com/wp-content/uploads/2020/05/Black_Box.png",
	},
];
