import { VideoCard } from "./Video/VideoCard";
import { videos } from "../data/videosObj";

export const HomePage = () => {
	const renderVideos = () => videos.map((v) => <VideoCard video={v} />);

	return (
		<div className="">
			<div className="flex flex-wrap ">{renderVideos()}</div>
		</div>
	);
};
