import { Link } from "react-router-dom";

export const Nav = ({ history }) => {
	return (
		<nav className="pa3 pa4-ns bg-black white ba b--black br2">
			<span
				className="link hover-gold  b f1 f-headline-ns tc db mb3 mb4-ns"
				href="#"
				title="Home"
				style={{ fontFamily: "Playfair Display SC" }}
			>
				CALL-YOUR-LOCAL-RECRUITER-TODAY
			</span>
			<div className="tc yellow f3 mb3">
				This site was made in 45 minutes, standby for update
			</div>

			<div className="tc  f3 mb3 uth">
				Email videos/unit ID{" "}
				<a
					className="red link"
					href="mailto:hnic@trebuchet.engineering?subject=Information Request&body= Good -insert TOD-, \n\n I am writting you today to request information; \n\n -insert request- \n\n -best\n -insert name-"
				>
					here
				</a>
			</div>
			<div className="tc pb3">
				<Link to="/" className="link dim gray f6 f5-ns dib mr3" href="#" title="ABOUT">
					Home
				</Link>
				<Link
					to="/about"
					className="link dim gray f6 f5-ns dib mr3"
					href="#"
					title="ABOUT"
				>
					About
				</Link>
			</div>
		</nav>
	);
};
