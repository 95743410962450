import { Link } from "react-router-dom";

export const About = () => {
	return (
		<article className="red">
			<header className=" bg-black-90 sans-serif">
				<div className="mw9 center pa4 pt5-ns ph7-l">
					<time className="f6 mb2 dib ttu tracked">
						<small>20210506</small>
					</time>
					<h3 className="f2 f1-m f-headline-l measure-narrow lh-title mv0">
						<span className="bg-black-90 lh-copy white pa1 tracked-tight">
							Attempting to archive the worst of times with the best of times
						</span>
					</h3>
					<h4 className="f3 bg-black-90 fw1 georgia i">
						I do engineering...not CSS , if you do frontend....get in touch
					</h4>
					<h5 className="f6 ttu tracked black-80">By dat dude</h5>
				</div>
			</header>
		</article>
	);
};
