import React from "react";
import { ReactVideo } from "reactjs-media";

export const Video = ({ video: { path, poster } }) => {
	return (
		<div>
			<ReactVideo src={`/vids/${path}`} primaryColor="green" poster={poster} />
		</div>
	);
};
