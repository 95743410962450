import { Route, Switch } from "react-router-dom";
import { HomePage } from "./Components/HomePage";
import { Nav } from "./Components/Nav";
import { About } from "./Components/About";

export const App = () => {
	console.log("submit videos 2 info@trebuchet.engineering");

	return (
		<div className="vh-100">
			<Nav />
			<Switch>
				<Route exact path="/" component={HomePage} />
				<Route exact path="/about" component={About} />
				<Route render={() => <h1 className="tc">404 not found</h1>} />
			</Switch>
		</div>
	);
};
