import { Video } from "./Video";

export const VideoCard = ({ video }) => {
	return (
		<div className="center w-40-l w-100 bg-black dark-green ba ma2-L mv5  bw2 br3 tc">
			<h1>{video.title}</h1>
			<Video video={video} />
		</div>
	);
};
